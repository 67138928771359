import React from 'react';
import Layout from '../components/layout';
import Card, { CardContent, CardImage } from '../elements/card';

const Contact = () => (
  <Layout>
    <div className="columns">
      <div className="column">
        <Card background="black">
          <CardContent>
            <div className="has-text-white">
              <content className="centered-content">
                <p className="title is-4 has-text-white">ANNE DINDAR VALIENTE</p>
                <p>Superviseure</p>
              </content>
              <content>
                <div className="columns">
                  <div className="column"></div>
                  <div className="column is-narrow">
                    <CardImage src="zebre" alt="zebre" size="is-128x128" rounded={true} />
                  </div>
                  <div className="column"></div>
                </div>
              </content>
              <content className="centered-content">
                <p><em>Titulaire du Certificat Européen de <br />Psychothérapie et affiliée à la FF2P</em></p>
                <p>GESTALT / SYSTEMIE / PROCESSUS CORPORELS</p>
                <p className="title is-4 has-text-white">Cabinet de Psychothérapie</p>
                <p><em>Ado, adulte, couple et famille</em></p>
              </content>
            </div>
          </CardContent>
          <CardImage src="rayures_zebre" alt="rayures" size="is-3by1" />
        </Card>
      </div>
      <div className="column">
        <Card background="primary">
          <CardContent>
            <div className="has-text-white">
              <content className="centered-content">
                <p className="title is-6 has-text-white">100 rue de l'Ouest - 75014 Paris</p>
                <p className="title is-6 has-text-white">Métro : Pernety sur la ligne 13</p>
                <p className="title is-6 has-text-white">06 80 58 32 86</p>
                <p>psy.annedindarvaliente@gmail.com</p>
              </content>
              <br />
              <content className="centered-content">
                <div className="columns">
                  <div className="column"></div>
                  <div className="column is-narrow">
                    <CardImage src="zebre" alt="zebre" size="is-128x128" rounded={true} />
                  </div>
                  <div className="column"></div>
                </div>
              </content>
              <br />
              <content className="centered-content">
                <p className="title is-6 has-text-white">Rendez-vous :</p>
                <p>Pour :........................<br />
                  Date :........................<br />
                  Heure :........................</p>
              </content>
              <br />
              <content className="centered-content">
                <div className="columns is-centered">
                  <div className="column is-2">
                    <p>Instagram</p>
                  </div>
                  <div className="column is-2">
                    <a href="https://www.instagram.com/psyandiva/?hl=fr">
                      @psyandiva
                    </a>
                  </div>
                  <div className="column is-2">
                    <CardImage size="is-24x24" src="instagram" alt="instagram" hoverable={true}/>
                  </div>
                </div>
              </content>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </Layout>
)

export default Contact;